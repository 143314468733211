import axios from '@/libs/axios'

export default class PricesArticles {

    findById (id) {        
        return axios.get(`/PreciosArticulos/ObtenerPorArticuloId/${id}`);
    }

    /**
     * O
     * @param {*} connectionId 
     * @param {*} articleId 
     * @param {*} branchId 
     * @returns 
     */
    GetPreciosArticulosByConnectionAndArticuloId(connectionId, articleId, branchId){
        const query = {
            idEmpresa: connectionId,
            idArticulo: articleId,
            idSucursal: branchId,
        }
        
        return axios.get(`/PreciosArticulos/ObtenerListaPreciosPorConexion`, {params: query});
    }

    store (payload) {
        return axios.post(`/Precios/GrabarPrecios`, payload)
    }

    GetPriceListReport(query) {
        return axios.get(`/PreciosArticulos/ObtenerReporteListaPrecios`, {params: query})
    }

    getAllPricesList(idEmpresa) {
        return axios.get(`/PreciosArticulos/ObtenerListaPrecios/${idEmpresa}`)
    }
}
